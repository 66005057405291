/* AboutCommunion.css */
.about-communion {
  background-color: white;
  padding: 30px;
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #D8AFAD;
}


.about-communion h2 {
  font-family: 'Pacifico', cursive; /* Ejemplo de fuente cursiva para el título */
}

.about-communion p {
  font-family: 'pacifico';
  text-align: justify; /* Opcional: para mejorar la legibilidad del texto largo */
  margin-bottom: 16px; /* Espaciado entre párrafos */
  text-align: center;
}
