/* Ajustes para App.css adaptados a tu proyecto */
.App {
  text-align: center;
  font-family: 'pacifico';
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Puedes ajustar el App-header para que se alinee con el diseño de tu invitación */
.App-header {
  background-color: #fff; /* Cambiado a blanco para un look más claro */
  color: #282c34; /* Texto oscuro para contraste */
  /* Otros estilos pueden permanecer o ajustarse según necesites */
}

/* Estilos adicionales específicos para tu proyecto pueden ir aquí */

/* App.css */
.App {
  text-align: center;
  padding: 0;
}

/* Puedes añadir más estilos globales según necesites */


.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

/* App.css o tu archivo CSS global */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}



.fixed-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1;
}