.gift-section {
    text-align: center;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #FBF1EF;
    color: #D8AFAD;
    padding: 30px;
}

.gift-section button {
  background-color: #BAD9D0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  font-family: 'pacifico';
}


.gift-message p {
  margin: 10px 0;
  font-size: 18px;
}
