/* Countdown.css */
.countdown {
  font-family: 'pacifico';
  font-size: 18px; /* Tamaño de fuente para móviles, se puede ajustar para otros dispositivos */
  text-align: center;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px; /* Bordes redondeados */
  text-shadow: 2px 2px 4px hsla(40,35%,92%,.5);
}

.countdown span {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #fff; /* Fondo para cada segmento del contador */
  border: 1px solid #ddd; /* Borde suave */
  border-radius: 4px; /* Bordes redondeados para los segmentos */
}

@media (min-width: 768px) {
  /* Estilos para pantallas más grandes (tablets, desktops) */
  .countdown {
    font-size: 24px; /* Tamaño de fuente más grande para pantallas más amplias */
    padding: 30px;
  }
}
