/* RSVPButton.css */
.rsvp-button-container {
  text-align: center;
  height: 30vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.7);
}

.rsvp-button-container button {
  background-color: #d8afad;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;
  font-family: 'pacifico';
}


