/* EventDetail.css */
.event-detail {
  text-align: center;
  padding: 30px;
  background-color: #FBF1EF;
}

.event-detail h2 {
  color: #D8AFAD;
}

.event-detail p {
  color: #D8AFAD;
}

.event-detail button {
  background-color: #BAD9D0;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 20px;
  font-family: 'pacifico'
}
