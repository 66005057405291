/* CelebrationDetails.css */
.celebration-details {
  margin: 20px auto;
  text-align: center;
}

.celebration-details p {
  margin: 5px 0;
}

.celebration-details button {
  background-color: #008CBA; /* Azul */
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
}

.celebration-details button:hover {
  background-color: #007B9A;
}
